@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Inter Font Faces - Corrected paths */
@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter.ttc') format('truetype-collection');
  font-weight: 400; /* Regular */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/InterVariable-Italic.ttf') format('truetype');
  font-weight: 400; /* Regular */
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/InterVariable.ttf') format('truetype');
  font-weight: 400; /* Regular */
  font-style: normal;
  font-display: swap;
}

/* Apply font to the entire document (optional, but recommended for consistency) */
html, body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0; /* Keep margin reset */
}

/* You might want to update code blocks too, or keep the monospace font */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Ensure your tailwind.config.js still has the theme override */
/* Example:
  theme: {
    extend: {
      fontFamily: {
        'georgia-pro': ['Georgia Pro', 'serif'],
      },
    },
    fontFamily: {
      sans: ['Georgia Pro', 'ui-sans-serif', 'system-ui', ...], // Default font
    },
  },
*/